import axios from "axios";
import { API_BASE, IMAGE_BASE } from "../utils/constants";
import AxiosInterceptor from "../utils/AxiosInterceptor";

const API_URL = API_BASE + "admin/";

const apiAuth = {
  login: (value) => axios.post(API_BASE + "/login", value),

  forgot: (value) => axios.post(API_BASE + "/password-reset", value),

  resetPassword: (value) => axios.post(API_BASE + "/change-password", value),
  username: (value) => axios.post(API_BASE + "/validate-username", value),
  privatePreSignedURL: (values) =>
    AxiosInterceptor().post(API_BASE + "/get-presigned-url-private", values),

  publicPreSignedURL: (values) =>
    AxiosInterceptor().post(API_BASE + "/get-presigned-url-public", values),

  privatePreViewSignedURL: (values) =>
    AxiosInterceptor().post(API_BASE + "/view-presigned-url-private", values),

  publicPreViewSignedURL: (values) =>
    AxiosInterceptor().post(API_BASE + "/view-presigned-url-public", values),
  // verifyOtp: (value) => axios.post(API_URL + 'verify-otp', value), resendOtp:
  // (value) => axios.post(API_URL + 'resend-otp', value), resetPassword: (value)
  // => axios.post(API_URL + "updatePassword", value),
};

export default apiAuth;
