import { REGEX } from "./Regex";

export const complexEmailRegex = (val) => {
    if (REGEX.EMAIL.test(val)) {
      if (val.match(/\+/g)?.length > 1) {
        return false;
      }
      if (val.includes(".+.")) {
        return false;
      }
      if (val.includes(".+")) {
        return true;
      }
      if (val.includes("+.")) {
        return true;
      }
      if (val.includes("++")) {
        return false;
      }

      return true;
    }
    return false;
};
