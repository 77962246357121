import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/AuthSlice";
import usersReducer from "../features/UsersSlice";
import MinMaxSlice from "../features/MinMaxSlice";
import AdminInfoSlice from "../features/AdminInfoSlice";

const persistMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  const { token } = store.getState().auth;
  localStorage.setItem("xtakemetoken", token);
  return result;
};

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    minMaxData: MinMaxSlice,
    adminInfo: AdminInfoSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(persistMiddleware),
});
