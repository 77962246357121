import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AxiosInterceptor from "../utils/AxiosInterceptor";

export const getAdminInfo = createAsyncThunk("adminInfo/getAdminInfo", async () => {
  let { data } = await AxiosInterceptor().post(`/get-info`);
  return data.data;
});

const AdminInfoSlice = createSlice({
  name: "adminInfo",
  initialState: {
    data: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdminInfo.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export default AdminInfoSlice.reducer;
