export const REGEX = {
  EMAIL: /^(?=[a-zA-Z0-9._]*[a-zA-Z])(?=[a-zA-Z0-9._])[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
  // /^(?!\.)[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/,
  USERNAME:
    /^[a-zA-Z0-9](_(?!(\. |_))|\.(?!( _|\.)) |[a-zA-Z0-9]){3,64}[a-zA-Z0-9]$/,
  PASSWORD: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
  POSTALCODE: /^\d{6}$/g,
  MBNO: /^\+?\d{1,4}?\d{10}$/g,
  NOSPACE_REGEX: /^(?!\s+$).*/,
};
