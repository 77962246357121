import { CardHeader, Container, Card ,CardContent} from '@mui/material';
import BackButton from "../components/Buttons/BackButton";

export default function CardLayout ({title,children,link}){
    return(
        <Container>
            <Card>
                <CardHeader title={<BackButton link={link} text={title} variant="h6" />} />
                <CardContent>
                    {children}
                </CardContent>
            </Card>
        </Container>
    )
}