import AxiosInterceptor from "../utils/AxiosInterceptor";

const videoService = {
  add: (value) => AxiosInterceptor().post("/add-gallery", value),
  edit: (value) => AxiosInterceptor().post("/edit-gallery", value),
  getById: (value) => AxiosInterceptor().post("get-one-gallery", value),
  delete: (value) => AxiosInterceptor().post("delete-gallery", value),
  getAll: (value) => AxiosInterceptor().post("/get-all-gallery", value),

  approveStatus: (value) => AxiosInterceptor().post("/approveStatus", value),

  getMinMax: (value) =>
    AxiosInterceptor().post("/get-all-minimum-maximum", value),
  getAllTags: (value) => AxiosInterceptor().post("/get-all-tags", value),
  getAllScenario: (value) =>
    AxiosInterceptor().post("/get-all-scenario", value),
};

export default videoService;
