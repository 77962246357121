import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import videoService from "../services/videoService";

export const getMinMax = createAsyncThunk("minMaxData/getMinMax", async () => {
  const { data } = await videoService.getMinMax();
  return data.minimumMaximumData;
});

const minMaxSlice = createSlice({
  name: "minMaxData",
  initialState: {
    data: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMinMax.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export default minMaxSlice.reducer;
