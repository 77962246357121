import React from "react";
import LoginRoutes from "./loginRoutes";
import MainRoutes from "./mainRoutes";
import { useSelector } from "react-redux";

const Routing = () => {
  const isLoggedIn = useSelector((state) => state.auth.token);

  return <>{isLoggedIn ? <MainRoutes /> : <LoginRoutes />}</>;
};

export default Routing;
